import React, { useState } from "react"
import { intervalToDuration } from "date-fns"

import "./styles.css"
import "./styles/tailwind-pre-build.css"
import Input from "./components/Input"
import Form from "./components/Form"

export default function App() {
  const today = new Date().toISOString().slice(0, -8)
  const yest = new Date()
  // Remove day
  yest.setDate(yest.getDate()-1)
  const yesterday = yest.toISOString().slice(0, -8)

  const [to, setTo] = useState(today)
  const [from, setFrom] = useState(yesterday)
  const [distance, setDistance] = useState(0)

  const handleSubmit = (event) => {
    event.preventDefault()
    setDistance(formatDistance(timeDistance(from, to)))
  }

  return (
    <div className="h-screen items-center justify-center flex bg-gray-100">
      <div className="md:w-1/2 grow w-screen px-1 sm:px-0">
        <section className="grid bg-white shadow-md grid place-items-center rounded px-8 pt-6 pb-8 mb-4 min-w-full">
          <h1 className="text-center font-bold text-xl text-gray-700 mb-2">Fast Calculator</h1>
          <Form
            onSubmit={handleSubmit}
          >
            <Input label="Start of Fast" value={from} onChange={(e) => setFrom(e.target.value)} />
            <Input label="End of Fast" value={to} onChange={(e) => setTo(e.target.value)} />

            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit"
              disabled={!to && !from}
            >
              Calculate
            </button>
          </Form>

          <div className="mt-5">
            <p className="text-center text-gray-500 text-lg">
              Time Fasted: {distance ? (<span className="italic">{pluralize(distance, "hr")}</span>) : null}
            </p>
          </div>
        </section>
      </div>
    </div>
  )
}

const timeDistance = (first, second) => {
  return intervalToDuration({
    start: new Date(first),
    end: new Date(second)
  })
}

const formatDistance = (data) => {
  const days = data?.days ? data.days * 24 : 0
  const hours = days + (data?.hours ? data.hours : 0)
  return hours
}

const pluralize = (count, noun, suffix = 's') =>
  `${count}${noun}${count >= 1 ? suffix : ''}`;
