import React from 'react'

export default function Input({ label, value, onChange }) {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="from"
      >
        {label}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="datetime-local"
        id="from"
        value={value}
        onChange={onChange}
      />
    </div>
  )
}